
//FIREBASE
exports.firebaseConfig = {
  apiKey: "AIzaSyBPsCAth8jKX-X_J1L3I8DHFtDP7T7gQjU",
  authDomain: "palko-stage.firebaseapp.com",
  databaseURL: "https://palko-stage.firebaseio.com",
  projectId: "palko-stage",
  storageBucket: "palko-stage.appspot.com",
  messagingSenderId: "590318707579",
  appId: "1:590318707579:web:0312949952f087a3e1d3de"
};