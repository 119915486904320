import React from 'react';
import { createStore, compose, applyMiddleware, } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from 'redux-saga'

// Reducers handle the internal state
import RootReducer from './reducers'
// Sagas handle fetch and side effects and external state
import RootSaga from './Sagas';
import { ConnectedRoutes } from './Routes';
import { Analytics } from '../components/Analytics';
import './App.css';


const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const preloadedState = {};
const store = createStore(
  RootReducer, // root reducer with router state
  preloadedState,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware
    ),
  ),
);

sagaMiddleware.run(RootSaga);

export function App() {
  return (
    <>
      <Provider store={store}>
        <ConnectedRoutes />

      </Provider>
      <Analytics />
    </>
  );
}

export default App;

